import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "../Header"
import Footer from "../Footer"

export const Layout = props => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return <PureLayout {...props} data={data} />
}

export const PureLayout = ({ children, data }) => {
  const title = data.site.siteMetadata.title
  return (
    <Fragment>
      <Header siteTitle={title} />
      <main>{children}</main>
      <Footer siteTitle={title} />
    </Fragment>
  )
}

PureLayout.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string,
      }),
    }),
  }).isRequired,
}

export default Layout
