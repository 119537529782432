import PropTypes from "prop-types"
import React from "react"
import { Heading, Box, Icon, Text, Stack, Flex } from "@chakra-ui/core"

export const Snippet = ({
  iconName,
  icon,
  heading,
  text,
  color,
  textColor,
}) => (
  <Box marginY={5} marginX={2}>
    <Flex flexDirection="row" alignItems="center">
      <Box paddingRight={4} paddingLeft={2}>
        {iconName && <Icon name={iconName} size={[16, 20]} color={color} />}
        {icon && <Box as={icon} size={[16, 20]} color={color} />}
      </Box>
      <Stack spacing={[2, 3]}>
        {heading && (
          <Heading as="h4" size="l" color={textColor}>
            {heading}
          </Heading>
        )}
        {text && (
          <Text fontSize="p" color={textColor}>
            {text}
          </Text>
        )}
      </Stack>
    </Flex>
  </Box>
)

Snippet.propTypes = {
  iconName: PropTypes.string,
  icon: PropTypes.func,
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
}

export default Snippet
