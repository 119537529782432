import React from "react"
import PropTypes from "prop-types"
import Image from "gatsby-image"
import styled from "@emotion/styled"

import { Flex } from "@chakra-ui/core"

export const HeroImage = props => {
  const { children, imageData } = props
  const BgImage = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  height: ${props => props.height || "100vh"};
  // Adjust image positioning (if image covers area with defined height) and add font-family for polyfill
  & > img {
    object-fit: ${props => props.fit || "cover"} !important;
    object-position: ${props => props.position || "50% 50%"} !important;
    font-family: 'object-fit: ${props =>
      props.fit || "cover"} !important; object-position: ${props =>
    props.position || "50% 50%"} !important;'
  }
`
  return (
    <Flex alignItems="center" position="relative">
      <BgImage fluid={imageData} />
      {children}
    </Flex>
  )
}

HeroImage.propTypes = {
  children: PropTypes.node,
  imageData: PropTypes.shape({
    base64: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    src: PropTypes.string,
    srcSet: PropTypes.string,
  }),
  height: PropTypes.string,
  fit: PropTypes.string,
  position: PropTypes.string,
}
export default HeroImage
