import React from "react"
import PropTypes from "prop-types"

import {
  Box,
  Input,
  InputGroup,
  Icon,
  InputLeftElement,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/core"

export const FormField = ({
  error,
  touched,
  color = "gray.300",
  iconName,
  icon,
  field,
  variant = "outline",
  placeholder,
  size = "md",
  id,
}) => {
  return (
    <Box paddingBottom={1}>
      <FormControl isInvalid={error && touched}>
        <InputGroup size={size}>
          <InputLeftElement>
            {iconName && <Icon name={iconName} color={color} />}
            {icon && <Box as={icon} color={color} />}
          </InputLeftElement>
          <Input
            {...field}
            variant={variant}
            placeholder={placeholder}
            aria-label={id}
            id={id}
          />
        </InputGroup>
        {error && <FormErrorMessage>{error}</FormErrorMessage>}
      </FormControl>
    </Box>
  )
}

FormField.propTypes = {
  error: PropTypes.string,
  touched: PropTypes.bool,
  color: PropTypes.string,
  iconName: PropTypes.string,
  icon: PropTypes.func,
  field: PropTypes.object.isRequired,
  variant: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  id: PropTypes.string.isRequired,
}

export default FormField
