import React from "react"
import {
  Box,
  Text,
  SimpleGrid,
  Stack,
  Flex,
  List,
  ListIcon,
  ListItem,
  Divider,
} from "@chakra-ui/core"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Snippet from "../components/Snippet"
import HeroImage from "../components/HeroImage"
import GoogleMap from "../components/GoogleMap"
import ContactUs from "../components/ContactUs"
import Section from "../components/Section"

import {
  snippetCollection,
  servicesList,
  companyNumber,
  companyEmail,
  companyName,
  whyRosaAlba,
  aboutMaria,
} from "../content"
import themes from "../utils/theme"
import { MdLoyalty } from "react-icons/md"

export const squareImage = graphql`
  fragment squareImage on File {
    childImageSharp {
      fluid(quality: 90, maxWidth: 960) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`

const IndexPage = () => {
  const imagesRequest = useStaticQuery(graphql`
    query {
      heroImageData: file(relativePath: { eq: "Alt_Temp_Hero.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      aaTLogo: file(relativePath: { eq: "LA_AAT_green_online_logo.png" }) {
        childImageSharp {
          fixed(width: 175) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      aboutMaria: file(relativePath: { eq: "business-2846221_1920.jpg" }) {
        ...squareImage
      }
      whyRoseImageData: file(relativePath: { eq: "IMG_7473.jpg" }) {
        ...squareImage
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Home" />
      <HeroImage
        imageData={imagesRequest.whyRoseImageData.childImageSharp.fluid}
      >
        <Flex
          width={["70%", "60%"]}
          textAlign="center"
          margin="0px auto"
          position="absolute"
          left={0}
          right={0}
          top={[30, 0]}
          bottom={0}
          justifyContent="center"
          alignItems="center"
        >
          <Text fontSize="xl" textColor={themes.dark.textColor}>
            <b>Qualifed professionals in Essex</b> here to help free up your
            time to let you focus on managing your business.
          </Text>
        </Flex>
      </HeroImage>

      {snippetCollection && (
        <Flex
          backgroundColor={themes.light.backgroundColor}
          justifyContent="center"
        >
          <SimpleGrid
            columns={{ st: 1, lg: 3 }}
            spacingY={[0, 4]}
            width={["100%", "90%"]}
          >
            {snippetCollection.map((snippet, index) => (
              <Snippet
                key={index}
                {...snippet}
                color={themes.light.iconColor}
                textColor={themes.light.secondaryTextColor}
              />
            ))}
          </SimpleGrid>
        </Flex>
      )}

      <Section
        padding={{
          padding: 8,
          paddingTop: 4,
        }}
        id="why"
        themeName="dark"
        headingText="Why Rosa Alba Accountancy Services?"
      >
        <Flex justifyContent="center">
          <Stack
            spacing={3}
            textAlign="center"
            width={["100%", "80%"]}
            textColor={themes.dark.textColor}
          >
            <Flex justifyContent="center">
              <Image fixed={imagesRequest.aaTLogo.childImageSharp.fixed} />
            </Flex>
            {whyRosaAlba &&
              whyRosaAlba.map((paragraph, index) => (
                <Text key={index} fontSize="p">
                  {paragraph}
                </Text>
              ))}
          </Stack>
        </Flex>
      </Section>

      {servicesList && (
        <Section
          padding={{
            paddingX: 8,
            paddingY: [4, 8],
          }}
          id="services"
          themeName="light"
          headingText="Our Services"
        >
          <Flex justifyContent="center">
            <List width={["100%", "90%"]}>
              <SimpleGrid columns={[1, 2, 3]} spacingY={[1, 2]}>
                {servicesList.map(service => (
                  <ListItem
                    key={service}
                    color={themes.light.secondaryTextColor}
                  >
                    <ListIcon icon="info" color={themes.light.iconColor} />
                    {service}
                  </ListItem>
                ))}
              </SimpleGrid>
            </List>
          </Flex>
        </Section>
      )}

      <SimpleGrid columns={{ sm: 1, lg: 2 }}>
        <Section
          padding={{
            paddingX: 8,
            paddingY: [4, 8],
          }}
          id="about"
          themeName="dark"
          headingText="About Maria Kingston"
        >
          <Stack spacing={2} textColor={themes.dark.textColor}>
            {aboutMaria &&
              aboutMaria.map((paragraph, index) => (
                <Text key={index} fontSize="p">
                  {paragraph}
                </Text>
              ))}
          </Stack>
        </Section>
        <Section
          display={{ st: "none", md: "block" }}
          themeName="dark"
          id="aboutImage"
          padding={{}}
        >
          <Image
            fluid={imagesRequest.aboutMaria.childImageSharp.fluid}
            alt="Maria Kingston hard at work"
          />
        </Section>
      </SimpleGrid>

      <SimpleGrid columns={{ sm: 1, lg: 2 }}>
        <Section
          padding={{
            padding: [4, 8],
            paddingBottom: [2, 4],
          }}
          id="contact"
          themeName="light"
          headingText="Get in Touch"
          color={themes.light.textColor}
          headingAlignment="center"
        >
          <Divider
            orientation="horizontal"
            borderColor={themes.light.borderColor}
          />
          <Flex
            flexDirection="column"
            justifyContent="center"
            textAlign="center"
            alignItems="center"
            color={themes.light.textColor}
          >
            <Stack spacing={4} paddingBottom={2}>
              <Box>
                <Text fontWeight="semibold">{companyName}</Text>
                <Text>Colchester, Essex</Text>
              </Box>
              <Box>
                <Text fontWeight="semibold">Call us:</Text>
                <Text>
                  <a href={`tel:${companyNumber}`}>{companyNumber}</a>
                </Text>
              </Box>
              <Box>
                <Text fontWeight="semibold">Email Us:</Text>
                <Text>{companyEmail}</Text>
              </Box>
            </Stack>
            <Box
              as={MdLoyalty}
              color={themes.light.iconColor}
              size={[10, 20]}
            />
          </Flex>
          <Divider
            orientation="horizontal"
            borderColor={themes.light.borderColor}
          />
        </Section>
        <Section
          padding={{
            padding: [4, 8],
          }}
          id="email"
          themeName="dark"
          headingText="Email Us"
          headingAlignment="center"
        >
          <ContactUs />
        </Section>
      </SimpleGrid>
      <GoogleMap />
    </Layout>
  )
}

export default IndexPage
