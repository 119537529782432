import React from "react"
import { object as yupObject, string as yupString } from "yup"

import { Button, useToast, Flex, Textarea } from "@chakra-ui/core"

import FormField from "./FormField"
import { MdPerson, MdLocalPostOffice, MdAccountBalance } from "react-icons/md"
import { Field, Formik, Form } from "formik"

const phoneRegExp = /([(+]*[0-9]+[()+. -]*)/g
const postcodeRegExp = /\b((?:(?:gir)|(?:[a-pr-uwyz])(?:(?:[0-9](?:[a-hjkpstuw]|[0-9])?)|(?:[a-hk-y][0-9](?:[0-9]|[abehmnprv-y])?)))) ?([0-9][abd-hjlnp-uw-z]{2})\b/i

const formFields = [
  {
    name: "name",
    icon: MdPerson,
    placeholder: "Name",
    id: "name",
  },
  {
    name: "emailAddress",
    iconName: "email",
    placeholder: "Email Address",
    id: "emailAddress",
  },
  {
    name: "telephone",
    iconName: "phone",
    placeholder: "Telephone",
    id: "telephone",
  },
  {
    name: "companyName",
    icon: MdAccountBalance,
    placeholder: "Company Name",
    id: "companyName",
  },
  {
    name: "postcode",
    icon: MdLocalPostOffice,
    placeholder: "Postcode",
    id: "postcode",
  },
]

const ContactUs = () => {
  const toast = useToast()
  return (
    <Formik
      initialValues={{
        name: "",
        emailAddress: "",
        telephone: "",
        companyName: "",
        postcode: "",
      }}
      validationSchema={yupObject({
        name: yupString()
          .min(3, "Must be longer than three characters")
          .required("Required"),
        emailAddress: yupString()
          .email("Invalid email address")
          .required("Required"),
        telephone: yupString()
          .matches(phoneRegExp, "Phone number is not valid")
          .required("Required"),
        companyName: yupString().min(1, "Must be longer than one character"),
        postcode: yupString().matches(
          postcodeRegExp,
          "Postcode is not a valid UK postcode"
        ),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          // const emailResponse = await fetch("/api/contact", {
          const emailResponse = await fetch(
            // "https://xwje14ernb.execute-api.us-east-1.amazonaws.com/dev/contact",
            "https://2danbdh9hd.execute-api.eu-west-1.amazonaws.com/production/contact",
            {
              method: "post",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(values),
            }
          )
          const responseJson = await emailResponse.json()
          if (responseJson.success) {
            setSubmitting(false)
            toast({
              title: "Email Sent",
              description: "We've sent your details across",
              status: "success",
              duration: 9000,
              isClosable: true,
            })
          } else {
            setSubmitting(false)
            toast({
              title: "Email failed to send",
              description: "Sorry please call or email directly",
              status: "error",
              duration: 9000,
              isClosable: true,
            })
          }
        } catch (error) {
          setSubmitting(false)
          toast({
            title: "Email failed to send",
            description: "Sorry please call or email directly",
            status: "error",
            duration: 9000,
            isClosable: true,
          })
        }
      }}
    >
      {formikProps => (
        <Form>
          {formFields &&
            formFields.map(({ name, ...rest }) => (
              <Field key={name} name={name}>
                {({ field, form }) => (
                  <FormField
                    error={form.errors[name]}
                    touched={form.touched[name]}
                    field={field}
                    {...rest}
                  />
                )}
              </Field>
            ))}
          <Field key="message" name="message">
            {({ field }) => (
              <Textarea
                {...field}
                placeholder="Message"
                id="message"
                aria-label="message"
              />
            )}
          </Field>
          <Flex flexDirection="column">
            <Button
              marginTop={4}
              variantColor="green"
              isLoading={formikProps.isSubmitting}
              type="submit"
            >
              Submit
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  )
}

export default ContactUs
