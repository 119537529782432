import PropTypes from "prop-types"
import React, { Fragment } from "react"

import { Flex, Heading, Box, Text } from "@chakra-ui/core"
import { MdLoyalty } from "react-icons/md"
import themes from "../../utils/theme"
import { companyNumber } from "../../content"

export const Header = ({ siteTitle = "", themeName = "light" }) => {
  const theme = themes[themeName] || themes.light
  return (
    <Fragment>
      <Flex
        as="header"
        backgroundColor={theme.backgroundColor}
        justifyContent="space-around"
        alignItems="center"
        padding={2}
      >
        <Flex alignItems="center" marginRight={[4, 0]}>
          <Heading
            as="h1"
            size="l"
            color={theme.textColor}
            paddingRight={[0, 2]}
            textAlign="center"
          >
            {siteTitle}
          </Heading>
          <Box as={MdLoyalty} color={theme.iconColor} size={10} />
        </Flex>
        <Box
          borderWidth={3}
          rounded="lg"
          color={theme.textColor}
          padding={[1, 2]}
          textAlign="center"
        >
          <Heading as="h1" size="l" color={theme.textColor}>
            Call us:
            <Text>
              <a href={`tel:${companyNumber}`}>{companyNumber}</a>
            </Text>
          </Heading>
        </Box>
      </Flex>
    </Fragment>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string.isRequired,
  themeName: PropTypes.string,
}

export default Header
