import { MdGavel, MdLightbulbOutline, MdSchedule } from "react-icons/md"

export const snippetCollection = [
  {
    icon: MdGavel,
    heading: "Reliable",
    text:
      "Striving to provide a good book-keeping service, worth its weight in gold, for your business.",
  },
  {
    icon: MdLightbulbOutline,
    heading: "Intelligent Working",
    text:
      "Taking the numbers and turning them into information, freeing you up to run your business.",
  },
  {
    icon: MdSchedule,
    heading: "Bespoke",
    text:
      "Spending the time to fully understand your business, its needs and requirements.",
  },
]

export const servicesList = [
  "Sage Line 50c Bookkeeper",
  "Double Entry Bookkeeping",
  "Accounting Work",
  "Bank Reconciliations",
  "Supplier Payments Made",
  "Credit Control",
  "Customer Receipts Received and Matched",
  "Supplier Postings & Account Reconciliations",
  "Customer Postings & Account Reconciliations",
  "Credit Card Postings & Reconciliations",
  "Creditors & Debtors Control Accounts Reconciled",
  "VAT Preparation & Submission",
  "CIS Filing",
  "Journal Postings",
  "Fixed Assets Register",
  "Nominal Ledger Reconciled",
  "Cash Flow Management",
  "Management Accounts",
]
export const whyRosaAlba = [
  "With experience of dealing with the day to day bookkeeping, from both the perspective of an external accountant and that of the person running the business, we are able to offer a wide degree of internal knowledge when working.",
  "We understand the bookkeeping and accounting demands involved in the running of your business, no matter the size. We provide the utmost care and attention for all businesses. We offer a specialist service in righting the ship if the books haven't been treated with the respect they deserve. This ensures you will have the most reliable information when running your business.",
  "We believe it is vital to maintain your financial records in good order. This information will be required by your external accountants, bankers, auditors and creditors to help your company to trade and get the help it requires to grow.",
  "Please let us know if you feel we can provide a service to you.",
]

export const aboutMaria = [
  "I have over 35 year's experience in the finance industry. I started my working life in 1977 working for a firm of Chartered Accountants. Initally, in my first years of working, I trained as an auditor accountant working primarily on reconciling accounts for incomplete records for sole traders. After this I worked in the large audit department working on small, medium and large private limited companies working with much larger data sets. I achieved the role of senior auditor complete with looking after a small team.",
  "I have continued to perform my work using the same principles that I first learnt in the accountancy practice. Audits may no longer be mandatory for all businesses, but those principals of how the data is treated to stay compliant with is. I am passionate about helping my clients grow their businesses by ensuring that their accounts are kept up to date and allowing them to concentrate on what they are good at and making informed decisions.",
]
export const companyName = "Rosa Alba Accountancy Services"
export const companyNumber = "07813548728"
export const companyEmail = "mariarosaservices@gmail.com"
