import React from "react"
import PropTypes from "prop-types"
import { Box, Heading } from "@chakra-ui/core"
import themes from "../../utils/theme"

export const Section = ({
  padding = { padding: 4 },
  themeName,
  id,
  headingText,
  children,
  headingPadding = {
    paddingBottom: [2, 4],
  },
  // headingAlignment = ["center", "unset"],
  headingAlignment = ["center"],
  ...rest
}) => {
  const theme = themes[themeName] || themes.dark
  return (
    <Box {...padding} backgroundColor={theme.backgroundColor} id={id} {...rest}>
      {headingText && (
        <Heading
          as="h2"
          size="lg"
          textAlign={headingAlignment}
          color={theme.headingColor}
          {...headingPadding}
        >
          {headingText}
        </Heading>
      )}
      {children}
    </Box>
  )
}

Section.propTypes = {
  padding: PropTypes.object,
  headingPadding: PropTypes.object,
  themeName: PropTypes.string,
  headingText: PropTypes.string,
  variant: PropTypes.string,
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  headingAlignment: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
}

export default Section
