import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import { Flex, Heading, Text, Box } from "@chakra-ui/core"
import themes from "../../utils/theme"

export const Footer = props => {
  const data = useStaticQuery(graphql`
    query {
      aaTLogo: file(relativePath: { eq: "LA_AAT_white_online_logo.png" }) {
        childImageSharp {
          fixed(width: 175) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return <PureFooter {...props} data={data}></PureFooter>
}

export const PureFooter = ({ siteTitle = "", themeName = "light", data }) => {
  const theme = themes[themeName] || themes.light
  const imageFixed =
    data &&
    data.aaTLogo &&
    data.aaTLogo.childImageSharp &&
    data.aaTLogo.childImageSharp.fixed

  return (
    <Box as="footer" backgroundColor={theme.backgroundColor} paddingBottom={2}>
      <Flex textAlign="center" flexDirection="column">
        <Heading as="h3" fontSize="sm" color={theme.textColor}>
          {imageFixed && (
            <Flex justifyContent="center">
              <Image fixed={imageFixed} />
            </Flex>
          )}
          <Text>
            Maria Kingston is licensed and regulated by the AAT under licence
            number 1332
          </Text>
          <Text>
            © {new Date().getFullYear()} {siteTitle}
          </Text>
        </Heading>
      </Flex>
    </Box>
  )
}

PureFooter.propTypes = {
  siteTitle: PropTypes.string,
  themeName: PropTypes.string,
  data: PropTypes.shape({
    aaTLogo: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fixed: PropTypes.shape({
          base64: PropTypes.string,
          width: PropTypes.number,
          height: PropTypes.number,
          src: PropTypes.string,
          srcSet: PropTypes.string,
        }),
      }),
    }),
  }),
}

export default Footer
