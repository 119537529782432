import React from "react"
import PropTypes from "prop-types"
// import { StaticGoogleMap, Marker, Path } from "react-static-google-map"
import Image from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

export const GoogleMap = props => {
  const data = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "staticmap.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return <PureGoogleMap {...props} data={data} />
}

export const PureGoogleMap = ({ data }) => {
  const mapData = data.desktop.childImageSharp.fluid
  return (
    <a
      href="https://www.google.com/maps/place/Great+Horkesley,+Colchester/@51.935634,0.8397718,13z/data=!3m1!4b1!4m5!3m4!1s0x47d906a84ffdaca3:0x8591197efb8cc7a!8m2!3d51.947116!4d0.875231"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Map of Location"
    >
      <Image fluid={mapData} />
    </a>
  )
  // This is as live request to our google requests - save the image to save api calls
  // return (
  //   <StaticGoogleMap
  //     size="640x200"
  //     className="img-fluid"
  //     apiKey="AIzaSyCeGz_rWNIiOD3yU8ScUtmZSH77nIf3RYA"
  //     scale="2"
  //     zoom="11"
  //   >
  //     <Marker location="51.9281, 0.8726" color="blue" label="A" size="mid" />
  //   </StaticGoogleMap>
  // )
}

PureGoogleMap.propTypes = {
  data: PropTypes.shape({
    desktop: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({
          base64: PropTypes.string,
          width: PropTypes.number,
          height: PropTypes.number,
          src: PropTypes.string,
          srcSet: PropTypes.string,
        }),
      }),
    }),
  }),
}

export default GoogleMap
