// export default {
//   lightShade: "#F2F4F6", // Use this color as the background for your dark-on-light designs, or the text color of an inverted design.
//   lightAccent: "#BCBDBB", //Accent colors can be used to bring attention to design elements by contrasting with the rest of the palette.
//   brand: "#EEA49D", // This color should be eye-catching but not harsh. It can be liberally applied to your layout as its main identity.
//   darkAccent: "B28491", // Another accent color to consider. Not all colors have to be used - sometimes a simple color scheme works best.
//   darkestShade: "E32A4D", // Use as the text color for dark-on-light designs, or as the background for inverted designs.
// }

export default {
  dark: {
    backgroundColor: "gray.100",
    headingColor: "purple.900",
    textColor: "black",
    secondaryTextColor: "orange",
    iconColor: "green",
  },
  light: {
    backgroundColor: "pink.300",
    headingColor: "white",
    textColor: "white",
    secondaryTextColor: "black",
    iconColor: "white",
    borderColor: "gray.300",
  },
}
